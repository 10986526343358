import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  MediaQuery,
  Chip,
  ExposedFilters,
  FilterUtils
} from '@spotahome/ui-library';
import { trans, useSoyuzExperiment } from '@spotahome/soyuz/client';
import Bell from '@spotahome/marketplace-common/src/components/icons/Bell';
import AlertButton from '@spotahome/marketplace-common/src/components/AlertButton';
import SoyuzAnalytics from '@spotahome/soyuz-analytics';

import { useFiltersContext } from '@spotahome/ui-library/src/tenant/context/FiltersContext';

import BILLS from '../Filters/bills-config';

import './FiltersExposed.scss';

const BILLS_NAMES = BILLS.map(({ name }) => name);

const FiltersExposed = ({
  cityId,
  currencyIsoCode,
  totalResults,
  onFiltersToggle,
  onFiltersUpdate,
  onFiltersClose,
  maxPriceInCity,
  minimumStaying,
  sortingAlgorithm
}) => {
  const { filters } = useFiltersContext();
  const { FromLaptop } = MediaQuery;
  const [filtersCount, setFiltersCount] = useState(0);
  const isSoldOutExperiment =
    useSoyuzExperiment('ShowSoldOutProperties').get('behaviour') === 'variant';

  useEffect(() => {
    const filtersWithoutExposedFilters = {
      ...filters,
      'move-in': undefined,
      'move-out': undefined,
      moveOutFrom: undefined,
      moveOutTo: undefined,
      moveInFrom: undefined,
      moveInTo: undefined,
      budget: undefined,
      'rentalType[]': undefined,
      'type[]': undefined,
      'topology[]': undefined,
      'features[]': filters['features[]'].filter(
        feature => !BILLS_NAMES.includes(feature)
      )
    };

    const disabledFilters = !isSoldOutExperiment
      ? ['includeBlockedProperties']
      : [];

    setFiltersCount(
      FilterUtils.getActiveFiltersCount(
        filtersWithoutExposedFilters,
        disabledFilters
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleFullFilterOpen = () => {
    SoyuzAnalytics.sendGA4Event('open-exposed-filters', {});
    onFiltersToggle();
  };

  const handleFiltersOpen = filterName => {
    SoyuzAnalytics.sendGA4Event('open-exposed-filters', {
      section: filterName
    });
    onFiltersClose();
  };
  const handleFiltersClose = filterName => {
    SoyuzAnalytics.sendGA4Event('close-exposed-filters', {
      section: filterName
    });
  };

  return (
    <div className="filters-exposed">
      <ExposedFilters
        filters={filters}
        cityId={cityId}
        currencyIsoCode={currencyIsoCode}
        totalResults={totalResults}
        onFiltersUpdate={onFiltersUpdate}
        onFiltersOpen={handleFiltersOpen}
        onFiltersClose={handleFiltersClose}
        maxPriceInCity={maxPriceInCity}
        minimumStaying={minimumStaying}
        sortingAlgorithm={sortingAlgorithm}
        showResults
      />
      <Chip.Filter
        label={trans('search.filter_header.more_filters')}
        className="filters-exposed__filter-button"
        onClick={handleFullFilterOpen}
        dataTest="more-filters-button"
        isSelected={!!filtersCount}
        renderTrailingIcon={() =>
          filtersCount ? <span>{filtersCount}</span> : null
        }
      />
      <FromLaptop>
        <AlertButton
          buttonClassName="filters-exposed__save-search-button"
          buttonProps={{
            size: 'small',
            color: 'primary',
            renderIcon: () => <Bell />
          }}
          cityId={cityId}
          filters={filters}
          dataTest="alert-button-exposed-filters"
        />
      </FromLaptop>
    </div>
  );
};

FiltersExposed.propTypes = {
  totalResults: PropTypes.number.isRequired,
  cityId: PropTypes.string.isRequired,
  currencyIsoCode: PropTypes.string.isRequired,
  onFiltersUpdate: PropTypes.func.isRequired,
  onFiltersClose: PropTypes.func.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  maxPriceInCity: PropTypes.number.isRequired,
  minimumStaying: PropTypes.number.isRequired,
  sortingAlgorithm: PropTypes.string.isRequired
};

export default FiltersExposed;
