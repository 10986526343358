import { Fragment, useState, memo } from 'react';
import PropTypes from 'prop-types';

import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { trans } from '@spotahome/soyuz/client';

import Rating from '../../Rating';
import Favourite from '../../Favourite';

import ImageCarousel from '../../ImageCarousel';

import HomecardLabelList from './HomecardLabelList';
import HomecardImagePlaceholder from './HomecardImagePlaceholder';

import styles from './HomecardImage.module.scss';

const IMAGES_TO_PRELOAD = 2;

const SingleImage = ({
  hasLazyLoad,
  inView,
  onLoad,
  image,
  imageSet,
  alt,
  children
}) => {
  const [imageLoaded, setImageLoaded] = useState(!hasLazyLoad);

  const handleImageLoad = () => {
    setImageLoaded(true);
    onLoad();
  };

  const homecardImageClasses = classNames(styles['home-card__image'], {
    [styles['home-card__image--hidden']]: !imageLoaded
  });

  const dataTest =
    hasLazyLoad && inView ? 'lazy-homecard-image' : 'homecard-image';

  return (
    <Fragment>
      <div className={styles['home-card__image-mask']} />
      {children}
      <picture>
        <img
          onLoad={handleImageLoad}
          srcSet={imageSet}
          className={homecardImageClasses}
          alt={alt}
          src={image}
          data-test={dataTest}
        />
      </picture>
      {!imageLoaded && <HomecardImagePlaceholder />}
    </Fragment>
  );
};

SingleImage.propTypes = {
  hasLazyLoad: PropTypes.bool.isRequired,
  inView: PropTypes.bool.isRequired,
  onLoad: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
  imageSet: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const HomecardImageContent = ({
  photos,
  onImageChange,
  hasLazyLoad,
  inView,
  onLoad,
  image,
  imageSet,
  alt,
  children
}) => {
  const [swipedImage, setSwipedImage] = useState(false);

  const handleImageChange = (action, options) => {
    setSwipedImage(true);
    onImageChange(action, options);
  };

  return photos?.length > 1 ? (
    <ImageCarousel
      onImageChange={handleImageChange}
      items={photos}
      ratio={ImageCarousel.RATIOS.HOMECARD}
      preloadRange={swipedImage ? IMAGES_TO_PRELOAD : 0}
      lazyLoad
      keepHeightWhileLoading
      withBackgroundChevrons
      isShowStatus
    >
      {children}
    </ImageCarousel>
  ) : (
    <SingleImage
      hasLazyLoad={hasLazyLoad}
      inView={inView}
      onLoad={onLoad}
      image={photos?.[0]?.src || image}
      imageSet={imageSet}
      alt={alt}
    >
      {children}
    </SingleImage>
  );
};

const HomecardImage = memo(
  ({
    favourite,
    handleFavourite,
    image,
    imageSet,
    renderLabels,
    title,
    photos,
    hasLazyLoad,
    onLoad,
    rating,
    onImageChange,
    onClick,
    isBooked
  }) => {
    const [hiddenOverlay, setHiddenOverlay] = useState(false);

    const [homecardRef, inView] = useInView({
      triggerOnce: true,
      threshold: 0.25
    });

    const handleImageChange = (action, options) => {
      onImageChange(action, options);
      setHiddenOverlay(!!options.currentItem?.renderItem); // Hide overlay if we are rendering custom item
    };

    const componentProps = isBooked
      ? { onClick }
      : { onClick, role: 'button', tabIndex: 0 };

    return (
      <Fragment>
        {!hiddenOverlay ? (
          <HomecardLabelList renderLabels={renderLabels} />
        ) : null}

        {handleFavourite && !hiddenOverlay && (
          <div className={styles['home-card__favourite-container']}>
            <Favourite
              selected={favourite}
              onClick={handleFavourite}
              iconWithBorder
            />
          </div>
        )}

        <div ref={homecardRef} className={styles['home-card__image-container']}>
          {hasLazyLoad && !inView ? (
            <HomecardImagePlaceholder />
          ) : (
            <div {...componentProps}>
              <HomecardImageContent
                photos={isBooked ? [photos?.[0]] : photos}
                onImageChange={handleImageChange}
                hasLazyLoad={hasLazyLoad}
                inView={inView}
                onLoad={onLoad}
                image={image}
                imageSet={imageSet}
                alt={title}
              >
                {rating && !hiddenOverlay ? (
                  <div className={styles['home-card__rating-container']}>
                    <Rating rating={rating} />
                  </div>
                ) : null}
              </HomecardImageContent>
            </div>
          )}
          {isBooked ? (
            <div className={styles['home-card__image-booked']}>
              {trans('homecard.is-booked.label')}
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
);

HomecardImage.propTypes = {
  favourite: PropTypes.bool,
  handleFavourite: PropTypes.func,
  image: PropTypes.string.isRequired,
  imageSet: PropTypes.string,
  onLoad: PropTypes.func,
  rating: PropTypes.number,
  renderLabels: PropTypes.func,
  title: PropTypes.string.isRequired,
  discount: PropTypes.shape({
    quantity: PropTypes.string,
    expiresAt: PropTypes.string
  }),
  hasLazyLoad: PropTypes.bool,
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      renderItem: PropTypes.func,
      src: PropTypes.string
    })
  ),
  onImageChange: PropTypes.func,

  isShowStatus: PropTypes.bool,
  onClick: PropTypes.func,
  isBooked: PropTypes.bool
};

HomecardImage.defaultProps = {
  imageSet: '',
  favourite: false,
  handleFavourite: null,
  onLoad: () => {},
  rating: null,
  renderLabels: () => {},
  discount: null,
  hasLazyLoad: true,
  photos: [],
  onImageChange: () => {},
  isShowStatus: false,
  onClick: () => {},
  isBooked: false
};

export default HomecardImage;
