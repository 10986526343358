import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz-trans/client';

import FooterSimplifiedBlock from './components/FooterSimplifiedBlock';
import FooterSimplifiedBlockTitle from './components/FooterSimplifiedBlockTitle';
import FooterSimplifiedBlockList from './components/FooterSimplifiedBlockList';
import FooterSimplifiedLink from './components/FooterSimplifiedLink';

const SpotahomeFooter = props => {
  const spotahomeLinks = [
    {
      url: props.howItWorks,
      transKey: 'footer.item.how_it_works'
    },
    {
      url: props.aboutUs,
      transKey: 'footer.item.about_us'
    },
    {
      url: props.blog,
      transKey: 'footer.item.blog'
    }
  ];
  const NO_FOLLOW_PAGES = [props.privacyPolicies, props.cookiesPolicy];
  return (
    <FooterSimplifiedBlock>
      <FooterSimplifiedBlockTitle text="Spotahome" />
      <FooterSimplifiedBlockList>
        {spotahomeLinks.map(({ transKey, url }) => (
          <FooterSimplifiedLink
            key={transKey}
            href={url}
            rel={NO_FOLLOW_PAGES.includes(url) ? 'nofollow' : ''}
          >
            {trans(transKey)}
          </FooterSimplifiedLink>
        ))}
      </FooterSimplifiedBlockList>
    </FooterSimplifiedBlock>
  );
};

SpotahomeFooter.propTypes = {
  aboutUs: PropTypes.string.isRequired,
  howItWorks: PropTypes.string.isRequired,
  cookiesPolicy: PropTypes.string.isRequired,
  privacyPolicies: PropTypes.string.isRequired,
  blog: PropTypes.string.isRequired,
  publishYourProperty: PropTypes.string.isRequired
};

export default SpotahomeFooter;
