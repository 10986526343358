import PropTypes from 'prop-types';

import { trans } from '@spotahome/soyuz/client';
import { SortingUtils } from '@spotahome/ui-library';

import './SortBy.scss';

const DEFAULT_OPTIONS = SortingUtils.getSortingOptions();

const SortBy = ({ handleSortByChange, orderBy }) => {
  const handleChange = e => {
    handleSortByChange(e.target.value);
  };

  // if POI search bar is used on map, we sort by distance. That option is hidden by default
  const hiddenFilterSort = SortingUtils.getHiddenSortingConfig(orderBy);

  const options = [
    ...DEFAULT_OPTIONS.map(option => (
      <option value={option.value} key={option.key}>
        {trans(option.key)}
      </option>
    )),
    ...(hiddenFilterSort
      ? [
          <option value={hiddenFilterSort.value} key={hiddenFilterSort.value}>
            {trans(hiddenFilterSort.key)}
          </option>
        ]
      : [])
  ];

  return (
    <div className="sort-by">
      <select
        className="sort-by__select"
        onChange={handleChange}
        value={orderBy}
      >
        {options}
      </select>
    </div>
  );
};

SortBy.propTypes = {
  handleSortByChange: PropTypes.func.isRequired,
  orderBy: PropTypes.string.isRequired
};
export default SortBy;
