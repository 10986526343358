import { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useFooterLinks } from '../../context/ExternalLink';

import FooterBottom from '../FooterBottom';

import SocialFooter from '../FooterContainer/SocialFooter';

import GlobeLanguageSwitcher from '../GlobeLanguageSwitcher/GlobeLanguageSwitcher';

import FooterSimplifiedSection from './components/FooterSimplifiedSection';
import FooterSimplifiedSeparator from './components/FooterSimplifiedSeparator';

import SpotahomeFooter from './SpotahomeFooter';
import LegalFooter from './LegalFooter';
import SupportFooter from './SupportFooter';
import AppFooter from './AppFooter';

import FooterSimplifiedBlock from './components/FooterSimplifiedBlock';

import styles from './FooterSimplified.module.scss';

const FooterSimplified = memo(({ onLocaleChange }) => {
  const externalLinks = useFooterLinks();

  return (
    <footer>
      <section className={classNames(styles.footer, styles['footer--beige'])}>
        <FooterSimplifiedSection>
          <SpotahomeFooter {...externalLinks} />
          <FooterSimplifiedBlock>
            <LegalFooter {...externalLinks} />
          </FooterSimplifiedBlock>
          <FooterSimplifiedBlock>
            <SupportFooter {...externalLinks} />
            <GlobeLanguageSwitcher className={styles.footer__app} />
          </FooterSimplifiedBlock>
          <FooterSimplifiedBlock className={styles.footer__tablet}>
            <AppFooter />
            <SocialFooter />
          </FooterSimplifiedBlock>
        </FooterSimplifiedSection>
      </section>
      <section className={styles.footer__bottom}>
        <FooterSimplifiedSeparator />
        <FooterSimplifiedSection>
          <FooterBottom onLocaleChange={onLocaleChange} />
        </FooterSimplifiedSection>
      </section>
    </footer>
  );
});

FooterSimplified.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string),
  onLocaleChange: PropTypes.func
};

FooterSimplified.defaultProps = {
  locales: null,
  onLocaleChange: null
};

export default FooterSimplified;
